
.footer{
  overflow: hidden;
  background-color: #fff;
border: solid;
border-width: 0.1rem;
border-left: 0;
border-bottom: 0;
border-right: 0;
border-color: rgb(231, 230, 229);

color: #a09e92;
font-size: 1.3rem;
  text-align: center;
  margin-top: 10rem;
  margin-bottom: 4rem;
  padding-top: 4rem;
}

@media screen and (max-width: 600px) {

  .footer{
    overflow: hidden;
    background-color: #fff;
  border: solid;
  border-width: 0.1rem;
  border-left: 0;
  border-bottom: 0;
  border-right: 0;
  border-color: rgb(231, 229, 229);
  
  color: #a09e92;
  font-size: 0.8rem;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
    padding-top: 2rem;
  }
}  