@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);

.footer{
  overflow: hidden;
  background-color: #fff;
border: solid;
border-width: 0.1rem;
border-left: 0;
border-bottom: 0;
border-right: 0;
border-color: rgb(231, 230, 229);

color: #a09e92;
font-size: 1.3rem;
  text-align: center;
  margin-top: 10rem;
  margin-bottom: 4rem;
  padding-top: 4rem;
}

@media screen and (max-width: 600px) {

  .footer{
    overflow: hidden;
    background-color: #fff;
  border: solid;
  border-width: 0.1rem;
  border-left: 0;
  border-bottom: 0;
  border-right: 0;
  border-color: rgb(231, 229, 229);
  
  color: #a09e92;
  font-size: 0.8rem;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
    padding-top: 2rem;
  }
}  

/* <meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<style>
body {
  font-family: Arial, Helvetica, sans-serif;
} */

.logocontainer{
    overflow: hidden;
    padding-top: 8px;
    padding-bottom: 16px;
    margin-left: 0px;
    border-width: 1px;
    text-align: left;
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 10px;
    background-color: #fff;
    color: red;
    max-width: 14rem;
}

.dropdownlist{
  list-style-type: none;
  list-style-position: outside;
  /* font-size: 20px; */
}

.logo{
    border-width: 12px;
}

.headerContainer{
    overflow: hidden;
    background-color: #fff;

    padding-top: 0px;
    padding-bottom: 16px;
    margin-left: 0px;

    border-width: 1px;
    text-align: left;
    margin-left: 60px;
    padding-top: 30px;
    margin-bottom: 5px;
    
}

.navbar1 {
  font-size: 16px;
  overflow: hidden;
  border-top: solid;
  border-bottom: solid;
  border-width: 1px;
  border-color:#ececec;
  letter-spacing: 1px;
  padding-top: 8px;
  padding-bottom: 16px;
  margin-left: 0px;
  margin-bottom: 0rem;
  background-color: white;
}

.navbar1 a {
  float: left;
  font-size: inherit;
  color: #686868;
  text-align: center;
  padding: 18px 26px 4px 0px;
  text-decoration: none;
  letter-spacing: inherit;
  background-color: white;
}

.dropdown {
  float: left;
  overflow: hidden;
  letter-spacing: inherit;
  background-color: inherit;
}

.dropdown .dropbtn {
  font-size: inherit;  
  border: none;
  outline: none;
  color: #686868;
  padding: 18px 26px 4px 0px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  letter-spacing: inherit;
}
/* 
.navbar1 a:hover, .dropdown:hover .dropbtn {
  background-color: white;
  color: #222;
}

.navbar1 a:active , .dropdown:active  .dropbtn {
  background-color: white;
  color: #ff2;
}

.navbar1 a:focus , .dropdown:focus  .dropbtn {
  background-color: white;
  color: #f22;
} */

.dropdown-content {
  display: none;
  position: absolute;
  margin-left: 0px;
  background-color: white;
  min-width: 160px;
  border-top:20px;
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: #686868;
  padding: 22px 18px 18px;
  text-decoration: none;
  display: block;
  text-align: left;
  border: solid;
  border-top: hidden;
  border-right: solid;
  border-width: 1px;
  border-color:#ecebe7;
  
}

.dropdown-content a:hover {
  background-color: #eee;  
}

.dropdown:hover .dropdown-content {
  display: block;
  
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}



.home,
.reports,
.products {
  display: flex;
  /* height: 90vh; */
  align-items: center;
  justify-content: center;
  font-size: 8rem;

}

.home:focus,
.reports:focus,
.products:focus   {
  color: red;
  
}

.home:active,
.reports:active,
.products:active   {
  color: blue;
}

.logocontainerside{
  overflow: hidden;
  width: 15rem;
  /* border: solid; */
  padding-top: 8px;
  padding-bottom: 16px;
  margin-left: 0px;
  border-width: 1px;
  text-align: left;
  padding-left: 0px;
  margin-top: 3rem;
  margin-bottom: 10px;
  background-color: #fff;
  margin-left: 0.7rem;
}

.navbar {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #999;
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-top: 10px;
  margin-left: 1rem;
  font-size: 2rem;
  background: none;
  padding-bottom: 0.2rem;
}

.menu-bars2{
  margin-left: 1rem;
  font-size: 2rem;
  margin-top: -32px;
  border: none;
}

.menu-text{
  font-size: 2rem;
  margin-top: 40px;
}

.nav-menu {
  background-color: #999;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  margin-top: 0px;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}


.gallery-background {
}
.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  /* padding: 8px 0px 8px 16px; */
  list-style: none;
  height: 60px;
  /* border: solid;
  border-color: green; */
}

.nav-text a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0.8rem;
  border: solid;
  border-color: #bbb;
  border-width: 0 0 0.1px 0;

}

.nav-text a:hover {
  background-color: #bbb;
}


.nav-text-long {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 1rem;
  list-style: none;
  height: 60px;
  /* border: solid;
  border-color: green; */
}

.nav-text-long a {
  text-decoration: none;
  color: white;
  font-size: 28px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0.8rem;
  border: solid;
  border-color: #bbb;
  border-width: 0 0 0.1px 0;

}

.nav-text-long a:hover {
  background-color: #bbb;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #999;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;

}

span {
  margin-left: 16px;
}

div.gallery {
  /* border: 1px solid red; */

}

.gallerycontainer{
  display: flex;
  flex-flow: column;
}

div.gallery:hover {
  /* border: 1px solid red; */
}

div.gallery img {
  width: 100%;
  height: auto;
}

div.imagedescription {
  /* padding: 1px; */
  text-align: center;
  color: #a09e92;
  font-size: 1rem;
  font-style: italic;
  margin-top: 0.2rem;
  margin-bottom: 6rem;
}

div.gallerytitle {
  font-size: 1.4rem;
  letter-spacing: 0.3rem;
  text-align: center;
  color: #c2ba8d;
  margin-bottom: 4rem;
  margin-top: 4rem;
  /* margin-left: 2rem; */
}

.documenttext {
  font-size: 1rem;
  line-height: 1.8;
  text-align: left;
  color: #4e4e4e;
  margin-bottom: 0rem;
  margin-top: 0rem;
  margin-left: 4rem;
  margin-right: 8rem;
  max-width: 80rem;
}

div.documenttitle {
  font-size: 1.4rem;
  text-align: left;
  color: #6b6b6b;
  margin-bottom: 1.5rem;
  margin-top: 4rem;
  margin-left: 4rem;
  /* margin-left: 2rem; */
}

* {
  box-sizing: border-box;
}

.responsive {
  /* padding: 0 8px; */
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  margin-bottom: 4.4rem;
  /* border: solid;
  border-color: #b11414; */
}

@media only screen and (max-width: 700px) {
  .responsive {
    
    width: 60%;
    /* margin: 6px 0; */
  }

  div.documenttext {
    font-size: 0.8rem;
    line-height: 1.8;
    text-align: left;
    color: #6b6b6b;
    /* margin-bottom: 28rem;
    margin-top: 0rem;
    margin-left: 4rem;
    margin-right: 20rem; */
    
  }
  
  div.documenttitle {
    font-size: 1.2rem;
    text-align: left;
    color: #6b6b6b;
    /* margin-bottom: 4rem;
    margin-top: 8rem;
    margin-left: 4rem; */
    /* margin-left: 2rem; */
  }

div.gallery-image-Home2 {
  width: 60%;
  margin-top: 0.2rem;
  border:solid;
  border-color: #b11414;
  
}
  
div.imagedescription {
  /* padding: 1px; */
  text-align: center;
  color: #a09e92;
  font-size: 0.7rem;
  font-style: italic;
  margin-top: 0.2rem;
  margin-bottom: 3rem;

}

div.gallerytitle {
  font-size: 0.9rem;
  letter-spacing: 0.3rem;
  text-align: center;
  color: #c2ba8d;
  margin-bottom: 2rem;
  margin-top: 2rem;
  /* margin-left: 2rem; */
}

  
}

@media only screen and (max-width: 500px) {
  .responsive {
    
    width: 80%;
  }

  
  div.documenttext {
    font-size: 0.8rem;
    line-height: 1.8;
    text-align: left;
    color: #4e4e4e;
    margin: 2rem;
    margin-bottom: 2rem;
    margin-top: 0rem;
    
  }
  
  div.documenttitle {
    font-size: 1.2rem;
    text-align: left;
    color: #4e4e4e;
    margin: 2rem;
    margin-top: 4rem;

    /* margin-bottom: 4rem;
    margin-top: 8rem; */
    /* margin-left: 4rem; */
    /* margin-left: 2rem; */
  }

  
div.imagedescription {
  /* padding: 1px; */
  text-align: center;
  color: #a09e92;
  font-size: 0.7rem;
  font-style: italic;
  margin-top: 0.1rem;
}

div.gallerytitle {
  font-size: 0.9rem;
  letter-spacing: 0.3rem;
  text-align: center;
  color: #c2ba8d;
  margin-bottom: 2rem;
  margin-top: 2rem;
  /* margin-left: 2rem; */
}

}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
