
/* <meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<style>
body {
  font-family: Arial, Helvetica, sans-serif;
} */

.logocontainer{
    overflow: hidden;
    padding-top: 8px;
    padding-bottom: 16px;
    margin-left: 0px;
    border-width: 1px;
    text-align: left;
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 10px;
    background-color: #fff;
    color: red;
    max-width: 14rem;
}

.dropdownlist{
  list-style-type: none;
  list-style-position: outside;
  /* font-size: 20px; */
}

.logo{
    border-width: 12px;
}

.headerContainer{
    overflow: hidden;
    background-color: #fff;

    padding-top: 0px;
    padding-bottom: 16px;
    margin-left: 0px;

    border-width: 1px;
    text-align: left;
    margin-left: 60px;
    padding-top: 30px;
    margin-bottom: 5px;
    
}

.navbar1 {
  font-size: 16px;
  overflow: hidden;
  border-top: solid;
  border-bottom: solid;
  border-width: 1px;
  border-color:#ececec;
  letter-spacing: 1px;
  padding-top: 8px;
  padding-bottom: 16px;
  margin-left: 0px;
  margin-bottom: 0rem;
  background-color: white;
}

.navbar1 a {
  float: left;
  font-size: inherit;
  color: #686868;
  text-align: center;
  padding: 18px 26px 4px 0px;
  text-decoration: none;
  letter-spacing: inherit;
  background-color: white;
}

.dropdown {
  float: left;
  overflow: hidden;
  letter-spacing: inherit;
  background-color: inherit;
}

.dropdown .dropbtn {
  font-size: inherit;  
  border: none;
  outline: none;
  color: #686868;
  padding: 18px 26px 4px 0px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  letter-spacing: inherit;
}
/* 
.navbar1 a:hover, .dropdown:hover .dropbtn {
  background-color: white;
  color: #222;
}

.navbar1 a:active , .dropdown:active  .dropbtn {
  background-color: white;
  color: #ff2;
}

.navbar1 a:focus , .dropdown:focus  .dropbtn {
  background-color: white;
  color: #f22;
} */

.dropdown-content {
  display: none;
  position: absolute;
  margin-left: 0px;
  background-color: white;
  min-width: 160px;
  border-top:20px;
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: #686868;
  padding: 22px 18px 18px;
  text-decoration: none;
  display: block;
  text-align: left;
  border: solid;
  border-top: hidden;
  border-right: solid;
  border-width: 1px;
  border-color:#ecebe7;
  
}

.dropdown-content a:hover {
  background-color: #eee;  
}

.dropdown:hover .dropdown-content {
  display: block;
  
}
