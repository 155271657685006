@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}



.home,
.reports,
.products {
  display: flex;
  /* height: 90vh; */
  align-items: center;
  justify-content: center;
  font-size: 8rem;

}

.home:focus,
.reports:focus,
.products:focus   {
  color: red;
  
}

.home:active,
.reports:active,
.products:active   {
  color: blue;
}

.logocontainerside{
  overflow: hidden;
  width: 15rem;
  /* border: solid; */
  padding-top: 8px;
  padding-bottom: 16px;
  margin-left: 0px;
  border-width: 1px;
  text-align: left;
  padding-left: 0px;
  margin-top: 3rem;
  margin-bottom: 10px;
  background-color: #fff;
  margin-left: 0.7rem;
}
