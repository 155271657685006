div.gallery {
  /* border: 1px solid red; */

}

.gallerycontainer{
  display: flex;
  flex-flow: column;
}

div.gallery:hover {
  /* border: 1px solid red; */
}

div.gallery img {
  width: 100%;
  height: auto;
}

div.imagedescription {
  /* padding: 1px; */
  text-align: center;
  color: #a09e92;
  font-size: 1rem;
  font-style: italic;
  margin-top: 0.2rem;
  margin-bottom: 6rem;
}

div.gallerytitle {
  font-size: 1.4rem;
  letter-spacing: 0.3rem;
  text-align: center;
  color: #c2ba8d;
  margin-bottom: 4rem;
  margin-top: 4rem;
  /* margin-left: 2rem; */
}

.documenttext {
  font-size: 1rem;
  line-height: 1.8;
  text-align: left;
  color: #4e4e4e;
  margin-bottom: 0rem;
  margin-top: 0rem;
  margin-left: 4rem;
  margin-right: 8rem;
  max-width: 80rem;
}

div.documenttitle {
  font-size: 1.4rem;
  text-align: left;
  color: #6b6b6b;
  margin-bottom: 1.5rem;
  margin-top: 4rem;
  margin-left: 4rem;
  /* margin-left: 2rem; */
}

* {
  box-sizing: border-box;
}

.responsive {
  /* padding: 0 8px; */
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  margin-bottom: 4.4rem;
  /* border: solid;
  border-color: #b11414; */
}

@media only screen and (max-width: 700px) {
  .responsive {
    
    width: 60%;
    /* margin: 6px 0; */
  }

  div.documenttext {
    font-size: 0.8rem;
    line-height: 1.8;
    text-align: left;
    color: #6b6b6b;
    /* margin-bottom: 28rem;
    margin-top: 0rem;
    margin-left: 4rem;
    margin-right: 20rem; */
    
  }
  
  div.documenttitle {
    font-size: 1.2rem;
    text-align: left;
    color: #6b6b6b;
    /* margin-bottom: 4rem;
    margin-top: 8rem;
    margin-left: 4rem; */
    /* margin-left: 2rem; */
  }

div.gallery-image-Home2 {
  width: 60%;
  margin-top: 0.2rem;
  border:solid;
  border-color: #b11414;
  
}
  
div.imagedescription {
  /* padding: 1px; */
  text-align: center;
  color: #a09e92;
  font-size: 0.7rem;
  font-style: italic;
  margin-top: 0.2rem;
  margin-bottom: 3rem;

}

div.gallerytitle {
  font-size: 0.9rem;
  letter-spacing: 0.3rem;
  text-align: center;
  color: #c2ba8d;
  margin-bottom: 2rem;
  margin-top: 2rem;
  /* margin-left: 2rem; */
}

  
}

@media only screen and (max-width: 500px) {
  .responsive {
    
    width: 80%;
  }

  
  div.documenttext {
    font-size: 0.8rem;
    line-height: 1.8;
    text-align: left;
    color: #4e4e4e;
    margin: 2rem;
    margin-bottom: 2rem;
    margin-top: 0rem;
    
  }
  
  div.documenttitle {
    font-size: 1.2rem;
    text-align: left;
    color: #4e4e4e;
    margin: 2rem;
    margin-top: 4rem;

    /* margin-bottom: 4rem;
    margin-top: 8rem; */
    /* margin-left: 4rem; */
    /* margin-left: 2rem; */
  }

  
div.imagedescription {
  /* padding: 1px; */
  text-align: center;
  color: #a09e92;
  font-size: 0.7rem;
  font-style: italic;
  margin-top: 0.1rem;
}

div.gallerytitle {
  font-size: 0.9rem;
  letter-spacing: 0.3rem;
  text-align: center;
  color: #c2ba8d;
  margin-bottom: 2rem;
  margin-top: 2rem;
  /* margin-left: 2rem; */
}

}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}