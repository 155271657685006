.navbar {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #999;
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-top: 10px;
  margin-left: 1rem;
  font-size: 2rem;
  background: none;
  padding-bottom: 0.2rem;
}

.menu-bars2{
  margin-left: 1rem;
  font-size: 2rem;
  margin-top: -32px;
  border: none;
}

.menu-text{
  font-size: 2rem;
  margin-top: 40px;
}

.nav-menu {
  background-color: #999;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  margin-top: 0px;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}


.gallery-background {
}
.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  /* padding: 8px 0px 8px 16px; */
  list-style: none;
  height: 60px;
  /* border: solid;
  border-color: green; */
}

.nav-text a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0.8rem;
  border: solid;
  border-color: #bbb;
  border-width: 0 0 0.1px 0;

}

.nav-text a:hover {
  background-color: #bbb;
}


.nav-text-long {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 1rem;
  list-style: none;
  height: 60px;
  /* border: solid;
  border-color: green; */
}

.nav-text-long a {
  text-decoration: none;
  color: white;
  font-size: 28px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0.8rem;
  border: solid;
  border-color: #bbb;
  border-width: 0 0 0.1px 0;

}

.nav-text-long a:hover {
  background-color: #bbb;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #999;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;

}

span {
  margin-left: 16px;
}
